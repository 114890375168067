body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* style scroll of componente --> AdminComponontes*/
.scroll-style {
  height: 480px;
  overflow-y: scroll;
}
.scroll-style::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scroll-style::-webkit-scrollbar:vertical {
  width: 5px;
}
.scroll-style::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}
